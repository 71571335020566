// $(document).ready(function(e) {

//         var maxAdults = 9;
//         var maxChildren = 9;
//         var maxInfants = 4
//         var maxInfents = 4
//         var childAgeArray = new Array();

//         var lang, destinationFilter;
//         if (typeof lang === 'undefined') {
//             lang = "EN";
//         }
//         if (typeof destinationFilter === 'undefined') {
//             destinationFilter = "ALL";
//         }

//         function getParameterByName(name, url) {
//             if (!url) url = window.location.href;
//             name = name.replace(/[\[\]]/g, "\\$&");
//             var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
//                 results = regex.exec(url);
//             if (!results) return null;
//             if (!results[2]) return '';
//             return decodeURIComponent(results[2].replace(/\+/g, " "));
//         }

//         //retrieving variables from parameter
//         var param_lang = getParameterByName('lang');
//         var param_destination = getParameterByName('destination');

//         var get_lang, get_destination;
//         if (param_lang == null || param_lang == '') {
//             get_lang = lang;
//         } else {
//             get_lang = param_lang;
//         }
//         if (param_destination == null || param_destination == '') {
//             get_destination = destinationFilter;
//         } else {
//             get_destination = param_destination;
//         }
//         console.log(param_lang, param_destination, get_lang, get_destination);
//         //language resources
//         var Language = function (language) {

//             this.name = ko.observable(language.name);
//             this.culture = ko.observable(language.culture);
//             this.ID = ko.observable(language.id);

//             //Lables
//             this.wherefrom = ko.observable(language.wherefrom);
//             this.whereto = ko.observable(language.whereto);
//             this.triptype = ko.observable(language.triptype);
//             this.roundtrip = ko.observable(language.roundtrip);
//             this.oneway = ko.observable(language.oneway);
//             this.departuredate = ko.observable(language.departuredate);
//             this.returndate = ko.observable(language.returndate);
//             this.numberpassengers = ko.observable(language.numberpassengers);
//             this.promotioncode = ko.observable(language.promotioncode);
//             this.optionaltext = ko.observable(language.optionaltext);
//             this.adulttext = ko.observable(language.adulttext);
//             this.infanttext = ko.observable(language.infanttext);
//             this.childrentext = ko.observable(language.childrentext);
//             this.pleaseselect = ko.observable(language.pleaseselect);
//             this.findflight = ko.observable(language.findflight);
//             this.porterdestination = ko.observable(language.porterdestination);
//             this.alldestinations = ko.observable(language.alldestinations);

//             //tooltip messages
//             this.tooltipadult = ko.observable(language.tooltipadult);
//             this.tooltipchildren1 = ko.observable(language.tooltipchildren1);
//             this.tooltipchildren2 = ko.observable(language.tooltipchildren2);
//             this.tooltipinfant1 = ko.observable(language.tooltipinfant1);
//             this.tooltipinfant2 = ko.observable(language.tooltipinfant2);
//             this.tooltipinfant3 = ko.observable(language.tooltipinfant3);

//             // validation messages
//             this.selectdeparturecity = ko.observable(language.selectdeparturecity);
//             this.selectdestinationcity = ko.observable(language.selectdestinationcity);
//             this.selectdeparturedate = ko.observable(language.selectdeparturedate);
//             this.selectreturndate = ko.observable(language.selectreturndate);
//             this.selectadult = ko.observable(language.selectadult);
//             this.needadult = ko.observable(language.needadult);
//             this.UnaccompaniedMinors = ko.observable(language.UnaccompaniedMinors);
//             this.invalidpromocode = ko.observable(language.invalidpromocode);
//             this.seasonalroutesP1 = ko.observable(language.seasonalroutesP1);
//             this.seasonalroutesP2 = ko.observable(language.seasonalroutesP2);
//             this.seasonalroutesTo = ko.observable(language.seasonalroutesTo);
//             this.seasonalroutesFrom = ko.observable(language.seasonalroutesFrom);
//         };

//         var languages = [
//             {
//                 name: "en-ca",
//                 culture: "English",
//                 id: "EN",

//                 //labels
//                 wherefrom: "Where from?",
//                 whereto: "Where to?",
//                 triptype: "Trip type",
//                 roundtrip: "Round trip",
//                 oneway: "One-way",
//                 departuredate: "Departure date",
//                 returndate: "Return date",
//                 numberpassengers: "Number of passengers",
//                 promotioncode: "Promotion code",
//                 optionaltext: "optional",
//                 adulttext: "adult(12+)",
//                 childrentext: "children(2-11)",
//                 infanttext: "infant(0-2)",
//                 pleaseselect: "Please select",
//                 findflight: "Find Flights",
//                 porterdestination: "Porter destinations",
//                 alldestinations: "All destinations",

//                 //tooltips
//                 tooltipadult: "To request a quote for a group booking of 10 or more passengers, click here.",
//                 tooltipchildren1: "To book travel for an unaccompanied minor, please contact the Porter Call Centre at 1-888-619-8622, ",
//                 tooltipchildren2: "Click here for complete details on unaccompanied minor travel",
//                 tooltipinfant1: "Infants must be between 7 days and less than 2 years old at the time of travel.",
//                 tooltipinfant2: "An infant under the age of 2 may travel at no additional charge if they are sitting on the adultâ€™s lap (16 or older) during the flight.",
//                 tooltipinfant3: "Only lap-held infants can be booked online. To purchase a seat for your infant, please contact the Porter Call Centre at 1-888-619-8622",

//                 //Validations
//                 selectdeparturecity: "Please select a departure city.",
//                 selectdestinationcity: "Please select a destination city.",
//                 selectdeparturedate: "Please select a departure date.",
//                 selectreturndate: "Please select a return date.",
//                 selectadult: "Please select at least one passenger.",
//                 needadult: "There must be at least one adult passenger for each infant passenger.",
//                 UnaccompaniedMinors: "Unaccompanied minor bookings are not permitted online. Please add an adult passenger to this reservation to continue, or contact the Call Centre at 1-888-619-8622 to complete a reservation for an unaccompanied minor.",
//                 invalidpromocode: "Alpha-Numeric characters only.",
//                 seasonalroutesP1: "Youâ€™ve chosen a seasonal destination. Porter flies to ",
//                 seasonalroutesP2: ". We hope youâ€™ll consider joining us then. Please reselect your travel dates.",
//                 seasonalroutesTo: " to ",
//                 seasonalroutesFrom: " from "
//             },
//             {
//                 name: "fr-ca",
//                 culture: "FranÃ§ais",
//                 id: "FR",

//                 //labels
//                 wherefrom: "Ville de dÃ©part",
//                 whereto: "Ville d'arrivÃ©e",
//                 triptype: "Type de voyage",
//                 roundtrip: "Aller-retour",
//                 oneway: "Aller simple",
//                 departuredate: "Date de dÃ©part",
//                 returndate: "Date de retour",
//                 numberpassengers: "Nombre de passagers",
//                 promotioncode: "Code de promotion",
//                 optionaltext: "facultatif",
//                 adulttext: "adulte(12+)",
//                 childrentext: "enfant(2-11)",
//                 infanttext: "bÃ©bÃ©(0-2)",
//                 pleaseselect: "Veuillez sÃ©lectionner",
//                 findflight: "Recherche un vol",
//                 porterdestination: "Porter destinations",
//                 alldestinations: "Toutes les destinations",

//                 //tooltips
//                 tooltipadult: "Pour demander le prix d'une rÃ©servation pour un group de 10 passager et plus, cliquez ici",
//                 tooltipchildren1: "Pour faire une rÃ©servation pour une personne dâ€™Ã¢ge mineur non accompagnÃ©e, veuillez appeler le centre dâ€™appels Porter au 1 888 619-8622. ",
//                 tooltipchildren2: "Cliquez ici pour connaÃ®tre toutes les consignes de voyage pour personnes dâ€™Ã¢ge mineur non accompagnÃ©es",
//                 tooltipinfant1: "Les passagers doivent Ãªtre Ã¢gÃ©s dâ€™entre sept jours et 24 mois au moment du voyage.",
//                 tooltipinfant2: "Seulement les bÃ©bÃ©s Ã¢gÃ©s de moins de 2 ans qui n'ont pas leur propre siÃ¨ge et que les parents (Ã¢gÃ©e de 16 ans ou plus) tiennent sur leur genoux peut rÃ©server en ligne gratuitement.",
//                 tooltipinfant3: "Veuillez contacter notre centre d'appels au 1 888 619-8622 pour acheter un siÃ¨ge pour votre bÃ©bÃ©.",

//                 //Validations
//                 selectdeparturecity: "Veuillez sÃ©lectionner une ville de dÃ©part.",
//                 selectdestinationcity: "Veuillez sÃ©lectionner une ville de destination.",
//                 selectdeparturedate: "Veuillez sÃ©lectionner une date de dÃ©part.",
//                 selectreturndate: "Veuillez sÃ©lectionner une date de retour.",
//                 selectadult: "Veuillez sÃ©lectionner au moins un passager.",
//                 needadult: "Il doit y avoir au moins un passager adulte pour chaque passager bÃ©bÃ©.",
//                 UnaccompaniedMinors: "Les rÃ©servations mineures non accompagnÃ©es ne sont pas autorisÃ©es en ligne. Veuillez ajouter un passager adulte Ã  cette rÃ©servation pour continuer, ou contactez le centre d'appels au 1-888-619-8622 pour effectuer une rÃ©servation pour un mineur non accompagnÃ©.",
//                 invalidpromocode: "CaractÃ¨res alpha-numÃ©riques seulement.",
//                 seasonalroutesP1: "Vous avez choisi une destination saisonniÃ¨re. Porter dessert ",
//                 seasonalroutesP2: ". Nous espÃ©rons vous voir ensuite. Veuillez choisir de nouvelles dates de voyage.",
//                 seasonalroutesTo: " au ",
//                 seasonalroutesFrom: " du "
//             }
// ];


//         function dropdown(id, name) {
//             var self = this;
//             self.id = id;
//             self.name = name;
//         }

//         function departureArrival(departureStation, arrivalStations) {
//             var self = this;
//             self.departureStation;
//             self.arrivalStations = arrivalStations;

//         }

//         ko.validation.configure({
//             registerExtenders: true,
//             messagesOnModified: true,
//             insertMessages: true,
//             parseInputAttributes: true,
//             messageTemplate: null,
//             decorateElement: true,
//             errorElementClass: 'invalid_error'
//         });


//         function handlerModel(data) {
//             var self = this;


//             //set language
//             self.selectedLanguage = ko.observable();
//             self.languages = ko.observableArray(
//                 ko.utils.arrayMap(data, function (i) {
//                     if (i.id == get_lang) {
//                         self.selectedLanguage = i;
//                     }
//                     return new Language(i);
//                 }));

//             //choose station type
//             self.stationType = ko.observable(get_destination);
//             self.stationchange = function (option) {
//                 option = self.selecteddeparts();
//                 self.listonchange(option);
//             }

//             //Trip type
//             self.TripType = ko.observable("return");

//             //Stations and dates
//             self.DepartureStation = ko.observable();
//             self.DestinationStation = ko.observable();
//             self.DepartureDate = ko.observable();
//             self.ReturnDate = ko.observable();

//             // Passengers
//             self.Adults = ko.observableArray();
//             self.AdultsSeleced = ko.observable(1).extend({ porterGreaterThan: 0 });
//             self.Children = ko.observableArray();
//             self.ChildrenSelected = ko.observable(0);

//             self.Infants = ko.observableArray();
//             self.InfantsSelected = ko.observable(0).extend({ infantsValidation: 0 });

//             self.PromoCode = ko.observable();

//             // Collect errors
//             self.errors = {
//                 EmptyDeparture: ko.observable(false),
//                 EmptyDestination: ko.observable(false),
//                 EmptyDepartureDate: ko.observable(false),
//                 EmptyReturnDate: ko.observable(false),
//                 PassengerIssues: ko.observable(false),
//                 EmptyPassenger: ko.observable(false),
//                 NeedAdult: ko.observable(false),
//                 UnaccompaniedMinors: ko.observable(false),
//                 InvalidPromocode: ko.observable(false),
//                 SeasonalRoutes: ko.observable(false)
//             };

//             // Reset errors
//             self.resetErrors = function (err) {
//                 for (var error in self.errors) {
//                     err.errors[error](false);
//                 }
//             }

//             // Validation
//             self.validate = function () {
//                 self.resetErrors(this);
//                 var _err = [];
//                 var letters = /^[a-zA-Z0-9]+$/;

//                 //Empty departure station
//                 if (!self.DepartureStation()) {
//                     self.errors.EmptyDeparture(true);
//                     _err.push('EmptyDeparture');
//                 }

//                 //Empty destination station
//                 if (!self.DestinationStation()) {
//                     self.errors.EmptyDestination(true);
//                     _err.push('EmptyDestination');
//                 }

//                 //Empty departure date
//                 if (!self.DepartureDate()) {
//                     self.errors.EmptyDepartureDate(true);
//                     _err.push('EmptyDepartureDate');
//                 }

//                 //Empty return date
//                 if (!self.ReturnDate()) {
//                     if (self.TripType() == "return") { //only validate when return
//                         self.errors.EmptyReturnDate(true);
//                         _err.push('EmptyReturnDate');
//                     }
//                 }

//                 //Empty passenger
//                 if (self.AdultsSeleced() + self.ChildrenSelected() + self.InfantsSelected() < 1) {
//                     self.errors.PassengerIssues(true);
//                     self.errors.EmptyPassenger(true);
//                     _err.push('EmptyPassenger');
//                 }

//                 //Adult infant issue
//                 if (self.InfantsSelected() > self.AdultsSeleced()) {
//                     self.errors.PassengerIssues(true);
//                     self.errors.NeedAdult(true);
//                     _err.push('NeedAdult');
//                 }

//                 //Unaccompanied minors
//                 if (self.AdultsSeleced() < 1 && self.ChildrenSelected() > 0) {
//                     self.errors.PassengerIssues(true);
//                     self.errors.UnaccompaniedMinors(true);
//                     _err.push('UnaccompaniedMinors');
//                 }


//                 //Invalid promocode
//                 if (self.PromoCode()) {
//                     var result = letters.test(self.PromoCode());
//                     if (!result) {
//                         self.errors.InvalidPromocode(true);
//                         _err.push('InvalidPromoCode');
//                     }
//                 }

//                 //Outbound is out of season
//                 if (self.isOutOfSeason(self.DepartureStation)) {
//                     self.errors.SeasonalRoutes(true);
//                     _err.push('SeasonalRoutes');
//                 }

//                 return _err;
//             }

//             ko.validation.rules['porterMin'] =
//             {
//                 validator: function (val, paramVal)
//                         {
//                             return val < paramVal;
//             },
//                 message: 'The field must less than {0}'
//             };

//             ko.validation.rules['porterGreaterThan'] =
//             {
//                 validator: function (val, paramVal) {
//                             return val > paramVal;
//                 },
//                 message: 'Please select at least one passenger.'
//             };
//             ko.validation.registerExtenders();
//             ko.validation.rules['infantsValidation'] =
//             {
//                 validator: function (val, paramVal) {
//                     return val <= self.AdultsSeleced();
//                 },
//                 message: 'There must be at least one adult passenger for each infant passenger.'
//             };


//             ko.validation.registerExtenders();


//             //all destinations
//             self.DepartureArrivaleArray = ko.observableArray();
//             self.DepartureStationsArray = ko.observableArray();
//             self.DestinationStationsArray = ko.observableArray();
//             self.AllStations = ko.observableArray();

//             //porter destinations
//             self.PorterStations = ko.observableArray();
//             self.PorterDestinationArray = ko.observableArray();

//             //Original json list
//             self.DepartureAndDestination = ko.observableArray();

//             self.selecteddeparts = ko.observable();
//             //station settings

//             //when destination type changes or dropdown changes
//             self.listonchange = function (newValue) {
//                 self.DestinationStationsArray([]);
//                 var _departurDest = ko.utils.arrayFirst(self.DepartureAndDestination, function (item) {
//                     if (item.DepartureCode === newValue) {
//                         return item;
//                     }
//                 });

//                 if (_departurDest != null && _departurDest.DestinationList != null) {
//                     ko.utils.arrayForEach(_departurDest.DestinationList, function (itemObj) {

//                         if (self.stationType() == "ALL") {
//                             self.DestinationStationsArray.push(new dropdown(itemObj.Key, itemObj.Value));
//                         } else {

//                             for (var i = 0; i < self.PorterStations().length; i++) {
//                                 if (itemObj.Key == self.PorterStations()[i].id) {
//                                     self.DestinationStationsArray.push(new dropdown(itemObj.Key, itemObj.Value));
//                                 }
//                             }

//                         }
//                     });
//                 }
//             }
//             self.DepartureStation.subscribe(function (newValue) {
//                 self.selecteddeparts(newValue);
//                 self.listonchange(newValue);
//             });



//             //Tooltips:
//             self.showinfantTooltip = ko.observable(false);
//             self.toggleinfantTooltip = function () { self.showinfantTooltip(!self.showinfantTooltip()) };

//             self.showchildrenTooltip = ko.observable(false);
//             self.togglechildrenTooltip = function () { self.showchildrenTooltip(!self.showchildrenTooltip()) };

//             self.showadultTooltip = ko.observable(false);
//             self.toggleadultTooltip = function () { self.showadultTooltip(!self.showadultTooltip()) };

//             //self.getSeasonalMessages = function (airport_code) {
//             //    var _location = _.filter(self.AllStations, function (unit) {
//             //        return unit.SeasonalMessages != null && unit.StationCode == airport_code;
//             //    })
//             //    if (typeof _location && _location.length > 0) {
//             //        return _location[0].SeasonalMessages[0].Message;
//             //    }
//             //    return null;
//             //}

//             self.seasonalDateStart = ko.observable();
//             self.seasonalDateEnd = ko.observable();
//             self.seasonalCity = ko.observable();

//             self.getSeasonalDates = function (airport_code) {
//                 var _location = _.filter(self.AllStations, function (unit) {
//                     return unit.SeasonalMessages != null && unit.StationCode == airport_code;
//                 })

//                 if (typeof _location && _location.length > 0) {
//                     self.seasonalCity = _location[0].DisplayName;
//                     var array = _location[0].SeasonalDates;
//                     console.log(array);
//                     return array[array.length - 1];
//                 }
//                 return null;
//             }


//             //check if it is a seasonal departure or destination
//             self.isOutOfSeason = function (airport_code) {

//                 var isWithinSeasonDate = false;
//                 var departureValid = true;
//                 var returnValid = true;

//                 //define depart and destination
//                 var _from = self.DepartureStation();
//                 var _to = self.DestinationStation();

//                 if (typeof _from != 'undefined' && typeof _to != 'undefined') {
//                     //var _seasonalMessagesFrom = self.getSeasonalMessages(_from);
//                     var _seasonalDatesFrom = self.getSeasonalDates(_from);
//                     //var _seasonalMessagesTo = self.getSeasonalMessages(_to);
//                     var _seasonalDatesTo = self.getSeasonalDates(_to);
//                 }

//                 //define trip type and dates
//                 var _departureDate = moment(self.DepartureDate()).format("YYYY-MM-DD");

//                 if (self.TripType() != "return") {
//                     var _returnDate = _departureDate;
//                 } else {
//                     var _returnDate = moment(self.ReturnDate()).format("YYYY-MM-DD");
//                 }

//                 // check if departure station has seasonal restrictions
//                 if (_seasonalDatesFrom != null) {
//                     if (_departureDate > _seasonalDatesFrom.StartDate && _departureDate < _seasonalDatesFrom.EndDate) {
//                         departureValid = true;
//                     } else {
//                         self.seasonalDateStart = moment(_seasonalDatesFrom.StartDate).format('MMMM DD, YYYY');
//                         self.seasonalDateEnd = moment(_seasonalDatesFrom.EndDate).format('MMMM DD, YYYY');
//                         departureValid = false;
//                     }

//                 }

//                 // check if destination station has seasonal restrictions
//                 if (_seasonalDatesTo != null) {
//                     if (_returnDate > _seasonalDatesTo.StartDate && _returnDate < _seasonalDatesTo.EndDate) {
//                         returnValid = true;
//                     } else {
//                         self.seasonalDateStart = moment(_seasonalDatesTo.StartDate).format('MMMM DD, YYYY');
//                         self.seasonalDateEnd = moment(_seasonalDatesTo.EndDate).format('MMMM DD, YYYY');
//                         returnValid = false;
//                     }
//                 }

//                 if (departureValid == true && returnValid == true) {
//                     return isWithinSeasonDate;
//                 } else {
//                     return !isWithinSeasonDate;
//                 }
//             }

//             //Initialise Adults dropdown
//             for (var j = 0; j <= maxAdults; j++) {
//                 self.Adults.push(new dropdown(j, j + " "));
//             }

//             //Initialise Children dropdown
//             for (var j = 0; j <= maxChildren; j++) {
//                 self.Children.push(new dropdown(j, j + " "));
//             }

//             //Initialise Infants dropdown
//             for (var j = 0; j <= maxInfants; j++) {
//                 self.Infants.push(new dropdown(j, j + " "));
//             }


//             ko.bindingHandlers.datepicker = {

//                 init: function (element, valueAccessor, allBindingsAccessor) {


//                     //initialize datepicker with some optional options
//                     var options = allBindingsAccessor().datepickerOptions || {
//                         numberOfMonths: 1,
//                         firstDay: 0,
//                         dateFormat: 'M dd, yy',
//                         altFormat: 'M dd, yy',
//                         buttonImage: 'https://www.porterescapes.com/Content/Images/Loader/calendarIcon.png',
//                         buttonImageOnly: true,
//                         showOn: 'both',
//                         minDate: '1',
//                         maxDate: '+2Y'
//                     };

//                     var funcOnSelectdate = function () {
//                         var observable = valueAccessor();
//                         observable($(element).datepicker("getDate"));
//                     }
//                     options.onSelect = funcOnSelectdate;
//                     options.onClose = function (selectedDate) { //same day selection support
//                         var minDate = $(this).datepicker('getDate');
//                         var newMin = new Date(minDate.setDate(minDate.getDate()));
//                         $("#ReturnDate").datepicker("option", "minDate", newMin);
//                     }

//                     $(element).datepicker(options);

//                     //handle the field changing
//                     ko.utils.registerEventHandler(element, "change", funcOnSelectdate);

//                     //handle disposal (if KO removes by the template binding)
//                     ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
//                         $(element).datepicker("destroy");
//                     });

//                 },
//                     update: function (element, valueAccessor) {
//                     try {
//                         var value = ko.utils.unwrapObservable(valueAccessor());

//                         //handle date data coming via json from Microsoft
//                         if (String(value).indexOf('/Date(') == 0) {
//                         value = new Date(parseInt(value.replace(/\/Date\((.*?)\)\//gi, "$1")));
//                     }

//                         current = $(element).datepicker("getDate");

//                         if (value - current !== 0) {
//                         $(element).datepicker("setDate", value);
//                     }
//                     }
//                     catch (error) {}
//                     },

//             };

//             self.makeBindingHandlerValidatable = function (handlerName) {
//                 var init = ko.bindingHandlers[handlerName].init;

//                 ko.bindingHandlers[handlerName].init = function (element, valueAccessor, allBindingsAccessor, self, bindingContext) {

//                     init(element, valueAccessor, allBindingsAccessor, self, bindingContext);

//                 return ko.bindingHandlers['validationCore'].init(element, valueAccessor, allBindingsAccessor, self, bindingContext);
//                             };
//             };

//             ko.bindingHandlers.minDate = {
//             update: function (element, valueAccessor) {
//                         try {
//                             var value = ko.utils.unwrapObservable(valueAccessor());
//                             if (value === undefined) return;
//                             if (String(value).indexOf('/Date(') == 0) {
//             value = new Date(parseInt(value.replace(/\/Date\((.*?)\)\//gi, "$1")));
//         }
//                         value.setDate(value.getDate() + 1);
//                         current = $(element).datepicker("option", "minDate", value);
//                     }
//                     catch (error) {};
//                 }
//             }

//             self.makeBindingHandlerValidatable('datepicker');


//             //self.errors = ko.validation.group(self);

//             //Methods
//             self.SubmitForm = function (formElement) {

//                 if (self.validate().length > 0) { // if errors

//                     return false;

//                 } else {

//                     var _departureObj = ko.utils.arrayFirst(self.AllStations, function (item) {
//                         if (item.CityCode === self.DepartureStation()) {

//                             return item;
//                         }
//                     });

//                     var _destinationObj = ko.utils.arrayFirst(self.AllStations, function (item) {
//                         if (item.CityCode === self.DestinationStation()) {
//                             return item;
//                         }
//                     });

//                     return true; // if no errors
//                 }
//             }







//         }


//         var viewModel = new handlerModel(languages);
//         ko.applyBindings(viewModel);


// //some clicking behaviorss
//         $(document).on('click', function (e) {
//             viewModel.showadultTooltip(false);
//             viewModel.showchildrenTooltip(false);
//             viewModel.showinfantTooltip(false);
//         })
//         $('.info-container').on('click', function (e) {
//             e.stopPropagation();
//         })


//         window.onload = function () {

//             if (typeof (Storage) !== "undefined") {

//                 var _allStations = sessionStorage.getItem("allStations");
//                 var _destinationList = sessionStorage.getItem("destinationList");
//                 var _departureStaionsArray = sessionStorage.getItem("departureStaionsArray");
//                 var _porterstations = sessionStorage.getItem("porterStations");
//                 var _porterdestinations = sessionStorage.getItem("porterDestinationList");

//                 if (!_allStations || !_destinationList || !_departureStaionsArray || !_porterstations) {
//                     populateStations();
//                 }
//                 else {

//                     viewModel.AllStations = JSON.parse(_allStations);
//                     viewModel.DepartureAndDestination = JSON.parse(_destinationList);
//                     viewModel.DepartureStationsArray(JSON.parse(_departureStaionsArray));
//                     viewModel.PorterStations(JSON.parse(_porterstations));
//                     //sessionStorage.clear();
//                 }

//             } else {
//                 populateStations();
//             }
//         }

//         //Populate staions from an API
//         function populateStations() {
//         $.ajax({
//             url: "https://api.flyporter.com/1.2/WidgetHandler/GetStations",
//             type: 'GET',
//             dataType: 'json',
//             success: function (apiData) {
//                 //debugger;
//                 var jsStations = JSON.parse(apiData)

//                 var errorMsg = jsStations.ErrorMsg;
//                 if (errorMsg != "") {
//                     return;
//                 }

//                 viewModel.AllStations = jsStations.Stations;


//                 if (viewModel.AllStations) {
//                     sessionStorage.setItem("allStations", JSON.stringify(viewModel.AllStations));
//                 }

//                 viewModel.SeasonalStations = function () {
//                     return jsStations.SeasonalDates != 'undefined';
//                 }

//                 //Update departure station list
//                 ko.utils.arrayForEach(viewModel.AllStations, function (depart) {
//                     if (typeof depart != 'undefined' && typeof depart.StationCode != 'undefined') {
//                         viewModel.DepartureStationsArray.push(new dropdown(depart.StationCode, depart.DisplayName));

//                         if (depart.Carriers.indexOf('PD') > -1) {
//                             viewModel.PorterStations.push(new dropdown(depart.StationCode, depart.DisplayName));
//                         }

//                         //if () {

//                         //}
//                     }
//                 })

//                 if (viewModel.DepartureStationsArray() && viewModel.PorterStations()) {
//                     sessionStorage.setItem("departureStaionsArray", JSON.stringify(viewModel.DepartureStationsArray()));
//                     sessionStorage.setItem('porterStations', JSON.stringify(viewModel.PorterStations()));
//                 }
//                 //Departure and Destinations list
//                 viewModel.DepartureAndDestination = jsStations.DestinationList;

//                 if (viewModel.DepartureAndDestination) {
//                     sessionStorage.setItem("destinationList", JSON.stringify(viewModel.DepartureAndDestination));
//                 }



//                 //productUpdateSuccess(data);
//             },
//             error: function (request, message, error) {
//                 //handleException(request, message, error);
//             }
//         });

//     }
// });
